<template>
  <div>
    <div class="ltn__breadcrumb-area text-left bg-overlay-white-30 bg-image " data-bs-bg="../../../public/assets/img/pageTitle/iletisim.png">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="ltn__breadcrumb-inner">
              <h1 class="page-title">{{ contact.heading }}</h1>
              <div class="ltn__breadcrumb-list">
                <ul>
                  <li><router-link to="anasayfa" ><span class="ltn__secondary-color"><i class="fas fa-home"></i></span> {{ langcontent.anasayfa }}</router-link></li>
                  <li>{{ contact.heading }}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="ltn__contact-address-area mb-90">
      <div class="container">

        <div class="row">
          <div class="col-lg-4">
            <div class="ltn__contact-address-item ltn__contact-address-item-3 box-shadow">
              <div class="ltn__contact-address-icon">
                <img src="../../../public/assets/img/0.png" alt="Icon Image">
              </div>
              <h3>{{ langcontent.eposta }}</h3>
              <p><a :href="'mailto:'+contact.mail">{{ contact.mail }}</a><br><br></p>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="ltn__contact-address-item ltn__contact-address-item-3 box-shadow">
              <div class="ltn__contact-address-icon">
                <img src="../../../public/assets/img/1.png" alt="Icon Image">
              </div>
              <h3>{{ langcontent.telefon }}</h3>
              <p><a :href="'tel:+'+contact.tel">{{ contact.tel }}</a><br><br></p>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="ltn__contact-address-item ltn__contact-address-item-3 box-shadow">
              <div class="ltn__contact-address-icon">
                <img src="../../../public/assets/img/2.png" alt="Icon Image">
              </div>
              <h3>{{ langcontent.adres }}</h3>
              <p>{{ contact.addr}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="google-map">
      <iframe :src='contact.map' width="100%" height="100%" style="border:0;"  frameborder="0" allowfullscreen="" aria-hidden="false" tabindex="0" loading="lazy"></iframe>

    </div>
  </div>
</template>
<style scoped>
.bg-overlay-white-30:before{
  background-image: url("../../../public/assets/img/pageTitle/pg.png");
  background-position-x: center;
}
</style>
<script>
import iso from "../../axios";
export default {
  props:['langcontent'],
  data() {
    return {
      contact: [],
    }
  },
  created() {
    iso.get('contact')
        .then(response => {
          this.contact = response.data[0]
        })
        .catch(error => {
          console.log(error);
        })
  },
  beforeRouteLeave (to, from, next) {
    next();
    window.scrollTo(0, 0);
  }
}
</script>